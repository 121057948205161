import formattedDate from "./date";
import * as Sentry from "@sentry/react";
import {
  monthNames,
  organisationWarningMessages,
  profileRequiredKeys,
  UserEventsDetails,
} from "../constants";
import { removeCookie } from "../utils/cookies";
import moment from "moment";
import { mainSchemeTypes, pensionType } from "../constants/multiForm";
import { createTheme } from "@mui/material";

const checkFieldError = (touched, errors, fieldName, index, subField) => {
  if (index || index === 0) {
    if (
      !(
        touched[fieldName] &&
        touched[fieldName][index] &&
        touched[fieldName][index][subField] &&
        errors[fieldName] &&
        errors[fieldName][index] &&
        errors[fieldName][index][subField]
      )
    ) {
      return false;
    } else {
      return (
        touched[fieldName][index][subField] &&
        errors[fieldName][index][subField]
      );
    }
  } else {
    if (!(touched[fieldName] && errors[fieldName])) {
      return false;
    } else return touched[fieldName] && errors[fieldName];
  }
};

const convertToNumber = (inputString) => {
  if (!inputString) {
    return "";
  }

  return parseInt(inputString.replace(/[^0-9]/g, ""));
};

const getPayPeriodName = (period) => {
  switch (period) {
    case 0:
      return "Weekly";

    case 1:
      return "Fortnightly";

    case 2:
      return "4-Weekly";

    case 3:
      return "Monthly";
  }
};

const poundConverter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const simpleCurrencyConverter = new Intl.NumberFormat();

const transformTempInvestmentChoices = (tempOptions) => {
  if (!(Array.isArray(tempOptions) && tempOptions.length)) {
    return [];
  }

  const filteredTempOptions = tempOptions
    .filter(Boolean)
    .filter((option) => option.investment_option);

  return filteredTempOptions.map((option) => ({
    value: option.investment_option.id,
    name: option.investment_option.id,
    percentage: option.specialist_value,
  }));
};

const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      let nextKey = prefix ? `${prefix}.${key}` : key;

      if (Number(key) && typeof Number(key) === "number") {
        nextKey = prefix ? `${prefix}[${key}]` : key;
      }

      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

const formatCurrency = (amount, inPounds = true) => {
  if (!amount) {
    amount = 0;
  }

  return inPounds
    ? poundConverter.format(Number(amount).toFixed(2))
    : simpleCurrencyConverter.format(Number(amount).toFixed(2));
};

const currencyFormatter = (amount) => {
  if (!amount) {
    amount = 0;
  }

  const formattedAmount = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  const arr = formattedAmount.split(".");
  return arr;
};

const fixedAmountToDecimal = (amount, decimal = 2) => {
  return Number(amount || 0).toFixed(decimal);
};

const decodeURLToString = (url) => {
  if (!url) {
    return "";
  }

  try {
    return decodeURI(url);
  } catch (error) {
    Sentry.captureException(error);
    console.log("Error while decoding url", url);
    return url;
  }
};

const truncateString = (inputString, maxLength) => {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.slice(0, maxLength) + "...";
  }
};

const removeAllCookies = () => {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};

const removeCookies = () => {
  removeAllCookies();
  removeCookie("user_session");
  removeCookie("organisation_id");
  removeCookie("scheme_id");
};

function serializeError(value) {
  if (value instanceof Error) {
    return {
      ...value,
      name: value.name,
      message: value.message,
      stack: value.stack,
    };
  }

  return value;
}

const isDecimalNumber = (number) => {
  if (!number) {
    return true;
  }

  if (!Number(number)) {
    return true;
  }

  return number - Math.floor(number) !== 0;
};

const sortArray = (a, b, keyName) => {
  const key = keyName;
  if (a[key].toLowerCase() < b[key].toLowerCase()) {
    return -1;
  }
  if (a[key].toLowerCase() > b[key].toLowerCase()) {
    return 1;
  }
  return 0;
};

const findItemBySelectedValue = (name, value, menucontainer) => {
  const foundItemByName = UserEventsDetails()?.find(
    (item) => item.name === name
  );
  const foundItemByValue = menucontainer?.find((item) => item.value === value);

  const fieldName = foundItemByName?.field_name || name;
  const fieldValue = foundItemByValue?.name || name;

  return {
    fieldName,
    fieldValue,
  };
};

const calculateAge = (dateOfBirth) => {
  if (!dateOfBirth) {
    return 0;
  }

  const dob = moment(dateOfBirth);
  const now = moment();
  const age = now.diff(dob, "years");
  return age;
};

const alternateCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus({ preventScroll: true });
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
};

const handleCopyToClipboard = (text) => {
  try {
    navigator.clipboard
      ? navigator.clipboard.writeText(text)
      : alternateCopyToClipboard(text);
  } catch (error) {
    console.log("Unable to copy to clipboard", error);
  }
};

const transformDataForCSV = (arr, columns) => {
  if (!arr?.length) return;

  const transformedArray = [];

  const header = columns.map((column) => column.label);

  transformedArray.push(header);

  for (const element of arr) {
    transformedArray.push(Object.values(element));
  }

  return transformedArray;
};

const generateCSVFromArray = (arr, columns) => {
  const updatedArray = transformDataForCSV(arr, columns);

  let csvContent = "data:text/csv;charset=utf-8,";

  updatedArray.forEach((rowArray) => {
    const row = rowArray.map((row) => `"${row}"`).join(",");
    csvContent += row + "\r\n";
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `events_${moment().unix()}.csv`);
  document.body.appendChild(link);

  link.click();
};

const findScheme = (schemes) => {
  const scheme = schemes?.find(
    (scheme) => scheme?.belongs_to_pension_type?.name === pensionType.NHS
  );

  return !!scheme;
};

const findBackendUserRoleId = (roles, userRole) => {
  const roleId = roles?.find((role) => role?.name === userRole)?.id;
  return roleId;
};

const compileOrganisationData = (
  organisations,
  backendUser = false,
  userRole
) => {
  let compiledOrganisations = organisations?.map((organisation) => {
    return {
      label: backendUser
        ? organisation?.name
        : organisation?.belongs_to_role?.belongs_to_organisation?.name,
      value: backendUser
        ? organisation?.id
        : organisation?.belongs_to_role?.belongs_to_organisation?.id,
      subDomain: backendUser
        ? organisation?.sub_domain
        : organisation?.belongs_to_role?.belongs_to_organisation?.sub_domain,
      role: backendUser
        ? findBackendUserRoleId(organisation?.has_many_roles, userRole)
        : organisation?.belongs_to_role?.id,
      isNHS: backendUser
        ? findScheme(organisation?.belongs_to_scheme)
        : findScheme(
            organisation?.belongs_to_role?.belongs_to_organisation
              ?.belongs_to_scheme
          ),
    };
  });

  const filteredOrganisations = compiledOrganisations.filter(
    (organisation) => organisation.role
  );

  return filteredOrganisations;
};

const parameterizedDetail = (id, name) => {
  const formattedName = name?.trim()?.toLowerCase()?.replace(/\s+/g, "-");
  const result = `${id}-${formattedName}`;
  return result;
};

const redirectToURL = (url, newTab) => {
  const tab = newTab ? "_blank" : "_self";
  if (url) {
    window.open(url, tab);
  }
};

const transformEventDateAndTime = (eventDate, eventTime, eventEndTime) => {
  const targetMoment = moment(
    `${eventDate} ${eventTime}`,
    "DD/MM/YYYY hh:mm a"
  );
  const targetEndTimeMoment = moment(
    `${eventDate} ${eventEndTime}`,
    "DD/MM/YYYY hh:mm a"
  );

  const currentTime = moment();

  return { targetMoment, targetEndTimeMoment, currentTime };
};

const isOneHourLeft = (eventDate, eventTime, eventEndTime) => {
  if (!eventDate || !eventTime) return false;
  const { targetMoment, targetEndTimeMoment, currentTime } =
    transformEventDateAndTime(eventDate, eventTime, eventEndTime);

  const oneHourBeforePresentation = targetMoment.subtract(1, "hours");

  return currentTime.isBetween(oneHourBeforePresentation, targetEndTimeMoment);
};

const isTenMinutesLeft = (eventDate, eventTime, eventEndTime) => {
  if (!eventDate || !eventTime) return false;
  const { targetMoment, targetEndTimeMoment, currentTime } =
    transformEventDateAndTime(eventDate, eventTime, eventEndTime);

  const tenMinutesBeforePresentation = targetMoment.subtract(10, "minutes");

  return currentTime.isBetween(
    tenMinutesBeforePresentation,
    targetEndTimeMoment
  );
};

const calculateIsTimeBetween = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return;
  }
  const userTimeZoneOffset = new Date().getTimezoneOffset();
  const currentTime = moment();
  const liveStartTime = moment(startTime).utcOffset(userTimeZoneOffset);
  const liveEndTime = moment(endTime).utcOffset(userTimeZoneOffset);
  return currentTime.isBetween(liveStartTime, liveEndTime);
};

const findPageBanner = (banners, pageName) => {
  const eventPageBanner = banners.find(
    (banner) =>
      banner?.appear_on_employee_pages &&
      banner?.employee_pages?.length &&
      banner?.employee_pages?.find(
        (page) => page?.page_name?.toLowerCase() === pageName?.toLowerCase()
      ) &&
      calculateIsTimeBetween(banner?.live_start_date, banner?.live_end_date)
  );
  return eventPageBanner;
};

const findActiveSchemeDetails = (schemes, pensionType, schemeTypeName) => {
  return schemes.find(
    (scheme) =>
      scheme.pension_type?.toLowerCase() === pensionType?.toLowerCase() &&
      scheme.scheme_type_name?.toLowerCase() === schemeTypeName?.toLowerCase()
  );
};

const findLatestActivePlanId = (plans) => {
  return plans?.length < 2
    ? plans?.find((plan) => plan)?.id
    : plans?.find((plan) => plan?.plan_status?.name === "Accepted")?.id;
};

const removeObjectKey = (obj = {}, key) => {
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    delete obj[key];
  }
  return obj;
};

const checkOrganisationDetailsValidation = (
  payPeriods,
  pensionFunds,
  providers
) => {
  if (!payPeriods) {
    return organisationWarningMessages.PAY_PERIOD;
  } else if (!pensionFunds) {
    return organisationWarningMessages.PENSION_FUND;
  } else if (!providers) {
    return organisationWarningMessages.PROVIDER;
  }
};

const checkActiveSchemes = (personalDetails) => {
  const activeSchemes = personalDetails?.active_schemes || [];
  return activeSchemes?.find((scheme) =>
    mainSchemeTypes?.includes(scheme?.scheme_type_name)
  );
};

const findPlanWithStatus = (plans = [], status) => {
  return plans?.find((plan) => plan?.plan_status?.name === status);
};

const findPlanWithScheme = (plans = [], schemeName) => {
  return plans.find((plan) => plan?.scheme_types?.name === schemeName);
};

const checkProfileStatus = (personalDetails = {}) => {
  let profileStatus = {
    completed: true,
    inCompletedTab: "1",
  };

  for (const entry of profileRequiredKeys) {
    if (
      !Object?.prototype?.hasOwnProperty?.call(personalDetails, entry?.key) ||
      !personalDetails[entry?.key]
    ) {
      profileStatus = {
        completed: false,
        inCompletedTab: entry?.tab,
      };
      break;
    }
  }

  return profileStatus;
};

const getMMMRedirectionURL = (organisationSubDomain, path) => {
  return organisationSubDomain
    ? `https://${organisationSubDomain}${process.env.REACT_APP_MMM_GENERIC_URL}${path}`
    : `${process.env.REACT_APP_MMM_URL}${path}`;
};

const mmmUniversalRedirection = (organisationSubDomain, path) => {
  const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectionURL = encodeURI(
    getMMMRedirectionURL(organisationSubDomain, path)
  );
  window.open(
    `https://${auth0Domain}/authorize?response_type=code&client_id=${auth0ClientId}&redirect_uri=${encodeURI(
      redirectionURL
    )}&scope=openid profile email&prompt=none`,
    "_blank"
  );
};

const legacyAppRedirection = (organisationSubDomain, path) => {
  const redirectionURL = organisationSubDomain
    ? `https://${organisationSubDomain}${process.env.REACT_APP_AVCWISE_GENERIC_URL}${path}`
    : `${process.env.REACT_APP_AVCWISE_URL}${path}`;
  window.location.href = redirectionURL;
};

const getLegacyAppURL = (organisationSubDomain) => {
  const legacyAppURL = organisationSubDomain
    ? `https://${organisationSubDomain}${process.env.REACT_APP_AVCWISE_GENERIC_URL}`
    : process.env.REACT_APP_AVCWISE_URL;
  return legacyAppURL;
};

const createMuiTheme = () => {
  const theme = createTheme({
    palette: {
      mmm: {
        main: "#7236ae",
        warning: "#FF832B",
        success: "#0B8E51",
        error: "#D4351C",
      },
    },
  });

  return theme;
};

const checkOrganisationSubDomain = (organisationSubDomain) => {
  const originSubdomain = window.location.hostname.split(".")[0];
  const expectedSubDomain = organisationSubDomain?.toString()?.toLowerCase();
  const genericSubDomain = process.env.REACT_APP_GENERIC_URL?.split(".")[0];
  const appGenericURL = `https://${process.env.REACT_APP_GENERIC_URL}`;
  const expectedOriginURL = `https://${expectedSubDomain}.${process.env.REACT_APP_GENERIC_URL}`;
  const isLocalEnvironment = process.env.REACT_APP_ENVIRONMENT === "local";
  if (
    !isLocalEnvironment &&
    expectedSubDomain &&
    originSubdomain !== expectedSubDomain
  ) {
    window.location.href = expectedOriginURL;
  } else if (
    !isLocalEnvironment &&
    !expectedSubDomain &&
    originSubdomain !== genericSubDomain
  ) {
    window.location.href = appGenericURL;
  }
};

const getCurrentAndNextMonthArray = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const nextMonth = (currentMonth + 1) % 12;

  return [
    { name: monthNames[currentMonth], value: monthNames[currentMonth] },
    { name: monthNames[nextMonth], value: monthNames[nextMonth] },
  ];
};

const getCurrentAndPrevYear = () => {
  const currentYear = moment().year();
  const previousYear = moment().subtract(1, "year").year();
  return [
    {
      name: previousYear,
      value: previousYear,
    },
    {
      name: currentYear,
      value: currentYear,
    },
  ];
};

const downloadBase64File = (base64String, fileType, extension) => {
  const binaryString = atob(base64String);
  const binaryData = new Uint8Array(binaryString?.length);
  for (let i = 0; i < binaryString?.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([binaryData], { type: fileType });
  let link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${moment()}${extension}`;
  link.click();
  URL.revokeObjectURL(link.href);
};

const generateLastYearsMenu = (years) => {
  const lastYears = Array.from({ length: years }, (_, i) =>
    moment().subtract(i, "years").year()
  );
  return lastYears.map((year) => ({
    name: year,
    value: year,
  }));
};

const getMonthListMenu = () => {
  return monthNames.map((month) => ({
    name: month,
    value: month,
  }));
};

const formatName = (inputName) => {
  if (!inputName.trim()) return ""; // Handle empty input case

  // Helper function to capitalize the first letter of each word
  const capitalizeWords = (name) => {
    return name.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Helper function to capitalize after "-" and "'"
  const capitalizeAfterSpecialChar = (name) => {
    return name.replace(
      /([-'][a-z])/g,
      (match) => match[0] + match[1].toUpperCase()
    );
  };

  // Trim leading/trailing spaces and normalize spaces between words
  const cleanedInput = inputName.replace(/\s+/g, " ").trim();

  // Capitalize words properly
  let formattedName = capitalizeWords(cleanedInput);
  formattedName = capitalizeAfterSpecialChar(formattedName);

  return formattedName;
};

export {
  checkFieldError,
  removeAllCookies,
  serializeError,
  convertToNumber,
  getPayPeriodName,
  transformDataForCSV,
  generateCSVFromArray,
  decodeURLToString,
  formatCurrency,
  getFieldErrorNames,
  formattedDate,
  fixedAmountToDecimal,
  isDecimalNumber,
  transformTempInvestmentChoices,
  checkOrganisationDetailsValidation,
  sortArray,
  truncateString,
  findItemBySelectedValue,
  handleCopyToClipboard,
  calculateAge,
  parameterizedDetail,
  compileOrganisationData,
  removeCookies,
  isOneHourLeft,
  findPageBanner,
  calculateIsTimeBetween,
  redirectToURL,
  isTenMinutesLeft,
  findActiveSchemeDetails,
  findLatestActivePlanId,
  removeObjectKey,
  checkActiveSchemes,
  checkProfileStatus,
  findPlanWithStatus,
  findPlanWithScheme,
  createMuiTheme,
  mmmUniversalRedirection,
  checkOrganisationSubDomain,
  legacyAppRedirection,
  getLegacyAppURL,
  getCurrentAndNextMonthArray,
  getMMMRedirectionURL,
  getCurrentAndPrevYear,
  getMonthListMenu,
  currencyFormatter,
  downloadBase64File,
  generateLastYearsMenu,
  formatName,
};
