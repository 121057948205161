import { Box, Typography, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import {
  TRACKING_NAF_OPTIONS,
  titles,
  genders,
} from "../../../../../constants";
import FormSelect from "../../../../generic-components/form-select";
import FormHeader from "../header/FormHeader";
import { personalDetailsValidationSchema } from "../../../../../schema";
import FooterButtons from "../footer/FooterButtons";
import useStepper from "../../../../../hooks/useStepper";
import Loader from "../../../../generic-components/loader";
import PropTypes from "prop-types";
import MaskedInput from "../../../../generic-components/masked-input";
import moment from "moment";
import debounce from "lodash.debounce";
import AutocompleteField from "../../../../generic-components/autocomplete-field";
import SearchIcon from "@mui/icons-material/Search";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  upadatePersonalDetails,
  updateUserInfo,
} from "../../../../../graphql/mutations/personalDetails";
import { setForm } from "../../../../../reducers/formReducer";
import { connect, useDispatch } from "react-redux";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import DateInput from "../../../../generic-components/date-input";
import TextInput from "../../../../generic-components/input-text";
import { setTempPlan } from "../../../../../reducers/tempPlan";
import SavePlan from "../../../../generic-components/save-plan";
import {
  checkEmployeeNumber,
  checkNiNumber,
} from "../../../../../graphql/queries/validator";
import { formatName } from "../../../../../helpers";
import {
  fetchAddressSuggestions,
  fetchAddressSuggestionsById,
} from "../../../../../graphql/mutations/addressSuggestions";

const PersonalDetailsForm = ({ user, form, organisation }) => {
  const {
    handleNext,
    formValues,
    setFormValues,
    loading,
    setIsLoadingSave,
    activeStep,
    setIsLoadingNext,
    btnClicked,
    setSavedToast,
    setErrorToast,
    personalDetails,
    setPersonalDetails,
    setSaveError,
    isLoadingNext,
    isLoadingSave,
  } = useStepper();
  const [options, setOptions] = useState([]);
  const [customError, setCustomError] = useState(null);
  const formInitialValues = {
    ...formValues,
    user_id: personalDetails.id,
    date_of_birth: formValues.date_of_birth || null,
  };
  const dispatch = useDispatch();
  const mobile = useMediaQuery("(max-width:600px)");

  const [addressSuggestions] = useMutation(fetchAddressSuggestions);
  const [addressSuggestionsById] = useMutation(fetchAddressSuggestionsById);

  const [checkEmployeeNumberExist, { loading: employeeNumberLoading }] =
    useLazyQuery(checkEmployeeNumber);
  const [checkNiNumberExist, { loading: niNumberLoading }] =
    useLazyQuery(checkNiNumber);

  const validateEmployeeNumber = async (value, fieldName) => {
    setCustomError({ ...customError, [fieldName]: "" });
    if (value && value?.trim() && organisation.id && user.id) {
      checkEmployeeNumberExist({
        variables: {
          employee_number: value?.trim().toLowerCase(),
          user_id: user.id,
          organisation_id: organisation.id,
        },
        onCompleted: (data) => {
          if (Array.isArray(data?.users_roles) && data?.users_roles?.length) {
            setCustomError({
              ...customError,
              [fieldName]:
                "This employee number is already exists please provide unique employee number.",
            });
          }
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }
  };

  const debounceValidateEmployeeNumber = debounce((value, fieldName) => {
    validateEmployeeNumber(value, fieldName);
  }, 1000);

  const debounceValidateNINumber = debounce((value, fieldName) => {
    validateNiNumber(value, fieldName);
  }, 1000);

  const validateNiNumber = async (value, fieldName) => {
    setCustomError({ ...customError, [fieldName]: "" });
    if ((value && !(value.length < 9), user.id)) {
      checkNiNumberExist({
        variables: {
          ni_number: value.replace(/ /g, "").replace(/_/g, "").toUpperCase(),
          id: user.id,
        },
        onCompleted: (data) => {
          if (Array.isArray(data?.users) && data?.users.length) {
            setCustomError({
              ...customError,
              [fieldName]:
                "This NI Number is already being used. If you need help, please contact our customer services on 01252 959 779.",
            });
          }
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }
  };

  const queryLoaders = useMemo(() => employeeNumberLoading || niNumberLoading);

  const isCustomError = useMemo(() => {
    return !!Object.keys(customError || []).filter((key) => customError[key])
      .length;
  }, [customError]);

  const handleSubmit = (values) => {
    if (isLoadingNext || isLoadingSave || queryLoaders) {
      return;
    }

    if (isCustomError) {
      return;
    }

    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else if (btnClicked === "save") {
      setIsLoadingSave(true);
    }

    const employeeDetails = {
      title: values.title,
      gender: values.gender,
      employee_number: values.employee_number?.trim(),
      delivery_address1: values.address1,
      delivery_address2: values.address2,
      delivery_county: values.county,
      date_of_birth: values.date_of_birth,
      email: personalDetails.email,
      first_name: values.first_name,
      last_name: values.last_name,
      mobile_number: values.mobile_number,
      ni_number: values.ni_number,
      delivery_postcode: values.postcode,
      delivery_town: values.town,
      telephone_number: values.mobile_number,
    };

    updateUserDetails({
      variables: employeeDetails,
      onCompleted: (userDetails) => {
        if (form.id) {
          updateApplication({
            variables: {
              id: form.id,
              step_number: btnClicked === "save" ? activeStep : activeStep + 1,
              updated_at: moment().format(),
              ...employeeDetails,
            },
            onCompleted: (data) => {
              setPersonalDetails({
                ...personalDetails,
                ...userDetails.updateUserDetails,
                latest_temp_plan: form.id,
                address1: userDetails.updateUserDetails.address_1,
                address2: userDetails.updateUserDetails.address_2,
              });
              if (btnClicked === "save") {
                setSavedToast(true);
              }
              dispatch(
                setForm({
                  ...data.update_temp_plans.returning[0],
                  latest_temp_plan: form.id,
                })
              );
              if (btnClicked === "save_and_next") {
                handleNext();
              }
              setIsLoadingNext(false);
              setIsLoadingSave(false);
            },
            onError: (error) => {
              setIsLoadingNext(false);
              setIsLoadingSave(false);
              setSaveError(error);
              setErrorToast(true);
            },
          });
        } else {
          setSaveError({ type: "error" });
          setErrorToast(true);
        }
      },
      onError: (error) => {
        setIsLoadingNext(false);
        setIsLoadingSave(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const [updateApplication] = useMutation(upadatePersonalDetails);
  const [updateUserDetails] = useMutation(updateUserInfo);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setInitialValues(formInitialValues);
    dispatch(
      setTempPlan({
        ...formValues,
        isValid: form.id || user.id ? true : false,
      })
    );
  }, [formValues]);

  const getAddressById = async (id, values) => {
    addressSuggestionsById({
      variables: {
        id: String(id) || "",
      },
      onCompleted: (data) => {
        if (data?.FetchAddressSuggestionById?.data) {
          const response = data?.FetchAddressSuggestionById;
          const result = {
            address1: response?.data.line_1,
            address2: response?.data.line_2,
            town: response?.data.town_or_city,
            county: response?.data.county,
            postcode: response?.data.postcode,
          };
          setInitialValues({
            ...values,
            ...result,
          });
          dispatch(setTempPlan(result));
        }
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const handleFetchAddressSuggestions = async (term) => {
    addressSuggestions({
      variables: {
        Term: String(term) || "",
      },
      onCompleted: (data) => {
        let options = [];
        if (data?.FetchAddressSuggestions?.data.suggestions?.length) {
          data?.FetchAddressSuggestions?.data.suggestions.forEach(
            (suggestion) => {
              options.push({
                label: suggestion.address,
                value: suggestion.id,
              });
            }
          );
        }
        setOptions(options);
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const getAddressDebounce = debounce((term) => {
    handleFetchAddressSuggestions(term);
  }, 1000);

  return (
    <Formik
      validationSchema={personalDetailsValidationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        setFormValues((prevValues) => ({ ...prevValues, ...values }));
        handleSubmit(values);
      }}
    >
      {({ values, handleBlur, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          {loading ? (
            <Box className="mt-30">
              <Loader />
            </Box>
          ) : (
            <>
              <ScrollToFieldError
                customError={customError}
                isCustomError={isCustomError}
              />
              <SavePlan isCustomError={isCustomError} />
              <Box className="application-page-container">
                <FormHeader heading="Personal Details" />
                <Typography className="sub-heading">
                  First up we need some personal details from you to confirm
                  your application with your employer
                </Typography>
                <Box className="stepper-field">
                  <MaskedInput
                    name="ni_number"
                    label="National Insurance Number *"
                    mask="** ****** *"
                    handleChangeValue={(e) => {
                      debounceValidateNINumber(e?.target?.value, "ni_number");
                    }}
                    customError={customError}
                    loading={niNumberLoading}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.ni_number || ""}
                  />
                  <TextInput
                    id="employee_number"
                    name="employee_number"
                    label="Employee Number *"
                    handleChangeValue={(e) => {
                      debounceValidateEmployeeNumber(
                        e?.target?.value,
                        "employee_number"
                      );
                    }}
                    customError={customError}
                    loading={employeeNumberLoading}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.employee_number || ""}
                  />
                  <FormSelect
                    name="title"
                    labelId="titleList"
                    menuItems={titles}
                    label="Title *"
                    width={mobile ? 50 : 30}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                  />
                  <FormSelect
                    name="gender"
                    labelId="genderList"
                    menuItems={genders}
                    label="Gender *"
                    width={mobile ? 50 : 30}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                  />
                  <TextInput
                    id="first_name"
                    name="first_name"
                    label="First Name *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.first_name || ""}
                    handleChangeValue={(e) =>
                      setFieldValue("first_name", formatName(e?.target?.value))
                    }
                  />
                  <TextInput
                    id="last_name"
                    name="last_name"
                    label="Last Name *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.last_name || ""}
                    handleChangeValue={(e) =>
                      setFieldValue("last_name", formatName(e?.target?.value))
                    }
                  />

                  <DateInput
                    id="date_of_birth"
                    name="date_of_birth"
                    label="Date of Birth *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.date_of_birth}
                    handleChangeValue={(value) =>
                      setFieldValue(
                        "date_of_birth",
                        moment(value).format("YYYY-MM-DD")
                      )
                    }
                    handleBlurValue={($event) => handleBlur($event)}
                  />

                  <TextInput
                    id="mobile_number"
                    name="mobile_number"
                    label="Phone Number *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.mobile_number || ""}
                  />

                  <TextInput
                    id="email"
                    name="email"
                    label="Email *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.email || ""}
                    disabled={true}
                  />

                  <AutocompleteField
                    name="address"
                    label="Type your address or postcode"
                    icon={<SearchIcon sx={{ fontSize: "1.25rem" }} />}
                    options={options}
                    getOptions={getAddressDebounce}
                    getOptionById={getAddressById}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                  />

                  <TextInput
                    id="address1"
                    name="address1"
                    label="Home Address#1 *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.address1 || ""}
                  />

                  <TextInput
                    id="address2"
                    name="address2"
                    label="Home Address#2"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.address2 || ""}
                  />

                  <TextInput
                    id="town"
                    name="town"
                    label="Town *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.town || ""}
                  />

                  <TextInput
                    id="county"
                    name="county"
                    label="County *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.county || ""}
                  />

                  <TextInput
                    id="postcode"
                    name="postcode"
                    label="Post Code *"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    value={values.postcode || ""}
                  />
                </Box>
              </Box>
              <FooterButtons isCustomLoading={queryLoaders} />
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    form: state.form.form,
    organisation: state?.organisation?.organisation,
  };
};

PersonalDetailsForm.propTypes = {
  user: PropTypes.object,
  organisation: PropTypes.object,
  form: PropTypes.object,
  scrollBehavior: PropTypes.object,
};

export default connect(mapStateToProps)(PersonalDetailsForm);
